import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import RoutePath from './routes/route'
import './App.css'
import './style/app/app.scss';
import { Web3authProvider } from "./context/web3authContext";
import { AuthProvider } from "./context/authContext";
import { AppProvider } from "./context/appContext";
import { usePageViews } from "./hooks/usePageViews";
import useGTMPageView from "./hooks/useGTMPageView";
import { REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID } from "./config";
import { getNormalizedPath, sendReactGAPageView } from './components/common/commonFnc';
import ReactGA from 'react-ga4';

function App() {

  useEffect(() => {
    if (REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
      // Add Google Analytic ID to .env file
      // ** Need to disable automatic pageview events in GA4 dashboard
      // ** To disable automatic pageview events triggered by history changes in Google Analytics 4 (GA4), navigate to your GA4 web stream settings in the Admin panel, then disable "Page changes based on browser history events" under Enhanced Measurement. 
      const normalizedPath = getNormalizedPath(window.location.pathname) || '';
      setTimeout(() => {
        ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
          {
            gtagOptions: {
              send_page_view: false,
              page: normalizedPath,
              page_location: window.location.origin + normalizedPath,
              page_path: normalizedPath,
            }
          }
        );
        sendReactGAPageView(window.location.pathname)
      }, 300)
    }
  }, []);

  usePageViews(); // Track page views
  useGTMPageView();
  return (
    <div className="App">
      <AppProvider>
        <Web3authProvider>
          <AuthProvider>
            <RoutePath />
          </AuthProvider>
        </Web3authProvider>
        {/* {
          posthog.has_opted_out_capturing() || posthog.has_opted_in_capturing() || isLevias || loginPage
            ? null
            : <CookieBanner isLevias={true} />
        } */}
      </AppProvider>
    </div>
  )
}

export default App;
