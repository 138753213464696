import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getNormalizedPath } from "../components/common/commonFnc";
import { REACT_APP_GOOGLE_TAG_MANAGER_ID } from "../config";

const useGTMPageView = () => {
    const location = useLocation();

    useEffect(() => {
        if (REACT_APP_GOOGLE_TAG_MANAGER_ID) {
            const normalizedPath = getNormalizedPath(location.pathname);
            if (normalizedPath) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "pageview",
                    page: normalizedPath,
                });
            }
        }
    }, [location]);
};

export default useGTMPageView;