import React, { useMemo, useState, useCallback, useContext, useEffect } from "react";
import RPC, { initWeb3, openloginAdapter, web3authModalParams } from "../../components/common/web3RPC";
import {
  REACT_APP_LOGIN_CALLBACK_WHITELIST
} from "../../config";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal, { LeviasSwalCorrect, LeviasSwalWrong } from "../../components/common/Swal";

import { LoginForm, LeviasLoginForm, LeviasLoginTwoFactorVerifyForm } from "../../components/auth/AuthForm";
import { authAction } from "../../store/Auth";
import AppWrapper from "../../components/wrappers/AppWrapper";
import {
  getToken,
  setToken,
  setValue,
  getValue,
  getWeb3authToken,
  setRedirect,
  setWalletAddress,
  getRedirect, removeValue, setWeb3authToken
} from "../../services/storage";
import { fetchData, postData } from "../../services/fetch";
import { Web3authContext } from "../../context/web3authContext";
import { AuthContext } from '../../context/authContext'
import { appContext } from "../../context/appContext";
import { isObject, isObjectEmpty } from "../../components/common/commonFnc";
import moment from "moment";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setWallet_address, web3auth, provider, init, loginWeb3, loginIfNotLogin } = useContext(Web3authContext);
  const { login, logout, user, setUser, updateCodeDMeasurement } = useContext(AuthContext)

  const [emailValidated, setEmailValidated] = useState(true);
  const [loading, setLoading] = useState(false);
  const [id_token, setIdToken] = useState(null);

  // const language = useSelector((state) => state.language.language);
  const { language } = useContext(appContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [idmsRegistrationStep, setIdmsRegistrationStep] = useState('setEmail')
  const [formValid, setFormValid] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [emailCodeValid, setEmailCodeValid] = useState(false)
  //const [emailVerificationCode, setEmailVerificationCode] = useState('')
  let emailVerificationCode = "";
  const state = location.state;
  const search = location.search;
  const query = new URLSearchParams(search);
  const { callId, signText } = useParams();

  const isLogout = query.get('logout') === 'true';
  const r_server = query.get('r_server') || '1'; // Portal = 1 (default)
  const referral_code = query.get('referral_code');
  const code_d_measurement_id = query.get('code_d_measurement_id');

  useEffect(() => {
    if (code_d_measurement_id) {
      updateCodeDMeasurement(code_d_measurement_id, 'is_view_login_page');
    }
  }, [code_d_measurement_id])

  useEffect(() => {

    // init();

    let redirect_to = query.get('r')
    if (redirect_to && redirect_to != 'null') {
      removeValue('redirect_uri')
      setRedirect(redirect_to)
      let response_type = query.get('response_type')
      setValue('login_params', { 'callId': callId, 'signText': signText, 'response_type': response_type });
      if (getToken()) {
        //ログインしている状態
        if (response_type === 'token') {
          goBackToCallbackUri()
        } else if (!isLogout && !id_token && user && !isObjectEmpty(user)) {
          setLoading(true)
          postData(`get-web3auth-token`, { idms_access_token: user?.idms_access_token }).then(responseWeb3 => {
            const _id_token = responseWeb3.data.web3auth_token
            setWeb3authToken(_id_token)
            setIdToken(_id_token)
          })
        }
        return;
      }
    } else {
      let response_type = query.get('response_type')
      if (response_type) {
        setValue('login_params', { 'response_type': response_type });
      }
      if (response_type === 'token' && getToken()) {
        goBackToCallbackUri();
        return;
      }
    }

    //flush
    let flush = query.get('f')
    if (flush == 'true') {
      removeValue('redirect_uri')
      removeValue('login_params')
      setRedirect('/')
    }

    if (window.location.href.match(/#sessionId/) && getWeb3authToken()) {
      //after login web3auth
      setLoading(true)
      setIdToken(getWeb3authToken())
    } else {
      const { redirect_uri, ...formdata } = location?.state || {};
      //let redirect_data = { returnFromLogin: !!redirect_uri };
      if (redirect_uri) {
        //redirect_data = JSON.parse(JSON.stringify(Object.assign(redirect_data, formdata)));
        if (redirect_uri) {
          setRedirect(redirect_uri)
        }
      }
    }
  });

  const getResponseType = async () => {
    let response_type = query.get('response_type')
    if (response_type && response_type !== 'null') {
      return response_type;
    }

    const login_params_str = await getValue('login_params')
    const login_params = login_params_str ? JSON.parse(login_params_str) : undefined;
    if (login_params && login_params['response_type']) {
      return login_params['response_type'];
    }

    return 'none';
  }

  useEffect(() => {
    console.log('ID token use effect')
    console.log(id_token)
    if (id_token && id_token !== true) {
      getResponseType().then(response_type => {
        if (response_type === 'token') {
          goBackToCallbackUri()
          return;
        }
        console.log('login if not login')
        loginIfNotLogin(id_token)
      })
    }
  }, [id_token])

  useEffect(() => {
    console.log('provider use effect')
    if (provider) {

      console.log("PRO")
      const rpc = new RPC(provider);
      rpc.getAccounts().then(address => {
        console.log('login', { address })

        setWalletAddress(address)
        setWallet_address(address)

        //navigate('/');
        postData(`account/update`, { wallet_address: address, r_server }).then(update_response => {
          goBackToCallbackUri()


          let redirect_to = getRedirect()
          console.log(redirect_to)
          if (redirect_to && redirect_to !== 'null') {
            console.log("IframeLogin")
            getValue('login_params').then(signObjStr => {
              const signObj = signObjStr ? JSON.parse(signObjStr) : undefined;
              try {
                afterWalletConnectFnc(signObj['callId'], signObj['signText'], provider);
              } catch (e) {
                console.log(e, e.message)
              }
            })
            return;
          }
          const isIframe = !!callId;
          getResponseType().then(response_type => {
            setLoading(true)

            if (isIframe) {
              goBackToCallbackUri()
              return
            }

            if (update_response.ok) {

              setLoading(false)
              let redirect_uri = getRedirect()
              if (redirect_uri && redirect_uri != null) {
                removeValue('redirect_uri')
                let redirect_data = { returnFromLogin: true };
                navigate(String(redirect_uri), { state: redirect_data });
                return;
              }

              // setWallet_address(address);
              goBackToCallbackUri()
            }
          });

        });
      })

    }
  }, [provider, callId])

  const textChangeHandler = (type, value) => {
    if (type === "email") {
      setEmail(value);
      emailValidator()
    } else if (type === "password") {
      setPassword(value);
      if (value.length > 7 && emailValidator) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else if (type === "verify-email-code") {
      emailVerificationCode = value;
      //setEmailVerificationCode(value)
      if (value.length > 5) {
        setEmailCodeValid(true);
      } else {
        setEmailCodeValid(false);
      }
    }
  };

  const goBackToCallbackUri = async () => {
    //iframeの場合
    const whitelist_a = REACT_APP_LOGIN_CALLBACK_WHITELIST.split(',')
    let redirect_uri = getRedirect() || "/";
    let matched = false;
    const response_type = await getResponseType()

    if (!redirect_uri.match(/^\//)) {
      whitelist_a.forEach(whitelist_url => {
        if (redirect_uri.indexOf(whitelist_url) === 0) {
          matched = true
        }
      })

      if (!matched) {
        console.log(redirect_uri)
        console.error(whitelist_a)
        throw new Error('not whitelist url');
      }
    }
    if (response_type === 'token') {
      //Octillion,Portal

      let _id_token = '';
      if (getWeb3authToken()) {
        _id_token = getWeb3authToken();
      } else {
        const userStr = await getValue('user_info');
        const user = userStr ? JSON.parse(userStr) : undefined;
        if (user) {
          const response = await postData(`get-web3auth-token`, { idms_access_token: user?.idms_access_token });
          _id_token = response.data.web3auth_token
        }
      }
      if (!_id_token) {
        LeviasSwalWrong.fire({
          icon: "error",
          title: "Error",
          text: 'エラーが発生しました。リロードして再度ログインして下さい。',
        })
        return;
      }

      if (redirect_uri.match(/\?/)) {
        redirect_uri += "&id_token=" + _id_token
      } else {
        redirect_uri += "?id_token=" + _id_token
      }
      if (r_server === '1') {
        redirect_uri += `&access_token=${getToken()}&leviasLogin=true`
      }
      window.parent.location.href = redirect_uri

      //Remove these 2 line if you want to save token and webtoken when login
      removeValue('web3authToken')
      removeValue('token')

      setLoading(false)
      return

    } else if (response_type === 'sign') {
      const signObjStr = await getValue('login_params');
      const signObj = signObjStr ? JSON.parse(signObjStr) : undefined;
      afterWalletConnectFnc(signObj['callId'], signObj['signText'], provider);
      return;
    } else if (response_type === 'none') {
      //portal login
      navigate(redirect_uri)
    }
  }

  const afterWalletConnectFnc = (callId, signText, provider) => {
    console.log(callId, signText)
    let redirect_to = getRedirect();
    const rpc = new RPC(provider);
    rpc.getAccounts().then(async (account) => {
      if (redirect_to.match(/\?/)) {
        redirect_to += "&callId=" + callId + "&address=" + account;
      } else {
        redirect_to += "?callId=" + callId + "&address=" + account;
      }

      //sig
      rpc.signMessage(signText).then(sig => {
        redirect_to += "&sign=" + sig
        redirect_to += "&signText=" + (signText || moment.utc().format('YYYYMMDDHHmmss'))

        removeValue('login_params')
        removeValue('redirect_uri')
        try {
          window.parent.location.href = redirect_to;
        } catch (e) {
          window.parent.postMessage({ action: 'loginComplete', redirect_to }, '*');
        }
        closeIframe()
      })
    })
  }

  const onLoginHandler = async () => {
    setLoading(true)
    const { redirect_uri, ...formdata } = location?.state || {};
    let redirect_data = { returnFromLogin: !!redirect_uri };
    if (formdata && isObject(formdata)) {
      redirect_data = JSON.parse(JSON.stringify(Object.assign(redirect_data, formdata)));
    }
    let response_type = await getResponseType()
    const isIframe = !!callId
    const isResponseToken = !!callId && (response_type === 'token');
    removeValue('web3authToken')
    const {
      id_token,
      need2FA
    } = await login(email, password, r_server || "1", setLoading, setIdmsRegistrationStep, !isResponseToken, redirect_uri, redirect_data, isIframe)
    if (!need2FA) {
      setIdToken(id_token)
    }
    return;
  }

  const closeIframe = () => {
    console.log("CLOSE")
    window.parent.postMessage({
      'func': '__close_portal_login',
      'message': 'Message text from iframe.'
    }, "*");
  }

  const onVerifyEmailHandler = async (value) => {
    if (emailVerificationCode == "") {
      emailVerificationCode = value;
    }
    setLoading(true)
    var response = await fetchData(`otp/loginWithOtp`, {
      email,
      idms_id: user.idms_id,
      otp: emailVerificationCode
    }, 'post', false);
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
        } else if (response.data.access_token) {
          setToken(response.data.access_token)
          const responseWeb3 = await postData(`get-web3auth-token`, { idms_access_token: user?.idms_access_token });
          const _id_token = responseWeb3.data.web3auth_token
          setWeb3authToken(_id_token)
          setIdToken(_id_token)
        } else if (response.data.message) {
          setLoading(false)
          LeviasSwalWrong.fire({
            title: "Error",
            text: response.data.message,
          });
        }
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
      });
    }
  }

  const nextStepHandler = (e) => {
    if (idmsRegistrationStep === "setEmail") {
      onLoginHandler();
    } else if (idmsRegistrationStep === "emailVerify") {
      onVerifyEmailHandler(e);
    } else if (idmsRegistrationStep === "emailVerifyIframe") {
      onVerifyEmailHandler(true, query.get('response_type'));
    }
  };


  const onShowHandler = (type, status) => {
    if (type === "password") {
      setShowPassword(status);
    }
  };

  const idmsRegistrationHandler = useCallback(() => {
    navigate("/register");
  }, []);

  const emailValidator = () => {
    setEmailValidated(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
  };

  const memberRegisterHandler = () => {
    navigate(`/register/idms/${callId}/${signText + location.search}`)
  }

  let body =
    <AppWrapper isLeviasLayout isLeviasHandler={'login'} className="auth-container" disableSidebar={true} isForm isCenter
      leviasIDMsg hasInput>
      <>
        <LeviasLoginForm
          onLoginHandler={onLoginHandler}
          textChangeHandler={textChangeHandler}
          idmsRegistrationHandler={idmsRegistrationHandler}
          email={email}
          language={language}
          password={password}
          emailValidator={emailValidator}
          emailValidated={emailValidated}
          formValid={formValid}
          onShowHandler={onShowHandler}
          showPassword={showPassword}
          nextStepHandler={nextStepHandler}
          loading={loading}
          memberRegisterHandler={memberRegisterHandler}
        />
      </>
    </AppWrapper>

  if (idmsRegistrationStep === 'emailVerify' || idmsRegistrationStep === 'emailVerifyIframe') {
    body =
      <AppWrapper isLeviasLayout isLeviasHandler={'login'} className="auth-container" disableSidebar={true} isForm
        isCenter hasInput>
        <LeviasLoginTwoFactorVerifyForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          emailCodeValid={emailCodeValid}
          textChangeHandler={textChangeHandler}
          nextStepHandler={nextStepHandler}
          loading={loading}
          email={email}
        />
      </AppWrapper>
  }

  return (
    <>
      {body}
    </>
  );
}

export default Login;
