import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './store/index'
import 'react-phone-number-input/style.css'
import './style/app/react-pdf.scss';
import './style/app/app.scss';
import posthog from 'posthog-js'; // new
import { REACT_APP_GOOGLE_TAG_MANAGER_ID } from './config';
import TagManager from 'react-gtm-module';


posthog.init( // new
  'phc_oPag6SvIe0aubz6StCkpeMau7AAoU1M4gGCeHb9tsYu', { api_host: 'https://app.posthog.com' }
)

// Initialize GTM only in production
if (process.env.NODE_ENV === 'production') {
  if(REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    const tagManagerArgs = {
      gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID, // Add GTM ID to .env file
    };
    TagManager.initialize(tagManagerArgs);
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);
