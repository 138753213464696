import { useLocation } from "react-router-dom";
import { sendReactGAPageView } from "../components/common/commonFnc";
import { useDidUpdateEffect } from "../services/hook";
import { useEffect, useState } from "react";

export function usePageViews() {
    const [previousPath, setPreviousPath] = useState('');
    const location = useLocation();

    useEffect(() => {
        // Update the previous path for next navigation
        setPreviousPath(location.pathname);
    }, [location.pathname]);

    useDidUpdateEffect(() => {
        sendReactGAPageView(location.pathname, previousPath);
    }, [location]);
}