import React from "react";
import AppWrapper from "../components/wrappers/AppWrapper";

const Maintenance = () => {
  return (
    <AppWrapper 
      isLeviasLayout
      className="auth-container" 
      disableSidebar={true}
      isCenter 
      leviasIDMsg>
      <div className="d-flex">
        <span style={{marginTop: 80+'%'}}> Levias ID login is under maintenance.</span>
      </div>
    </AppWrapper>
  );
};

export default Maintenance;
