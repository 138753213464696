import React, { useMemo, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Swal, {
  LeviasSwalCorrect,
  LeviasSwalWrong,
} from "../../components/common/Swal";
import "react-html5-camera-photo/build/css/index.css";
import { fetchData } from "../../services/fetch";

import { IDMSRegistrationForm } from "../../components/auth/AuthForm";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { BottomModal } from "../../components/common/BottomModal";
import { strings, translations } from "../../services/localization";
import { AuthContext } from "../../context/authContext";
import { sendReactGAPageView } from "../../components/common/commonFnc";

function Register({ completeWeb3Auth }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { referral_code } = location.state || {};
  const { callId, signText } = useParams();

  const language = useSelector((state) => state.language.language);

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailValidated, setConfirmEmailValidated] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  //const [emailVerificationCode, setEmailVerificationCode] = useState("");
  let emailVerificationCode = "";
  const [emailCodeValid, setEmailCodeValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  //const [smsVerificationCode, setSmsVerificationCode] = useState("");
  let smsVerificationCode = "";
  const [phoneCodeValid, setPhoneCodeValid] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [tempToken, setTemptoken] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNameValid, setAccountNameValid] = useState(false);
  const [confirmedAccName, setConfirmedAccName] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState("");

  const [loading, setLoading] = useState(false);
  const [idmsRegistrationStep, setIdmsRegistrationStep] =
    useState("signUpForm");

  const [checkedTerm, setCheckedTerm] = useState(false);
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [checkedvalid, setCheckedValid] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [onShow, setOnShow] = useState(false);

  const [imageFile, setImageFile] = useState();
  const [isLeviasLayout, setIsLeviasLayout] = useState(true);
  const [isLeviasTeamAndPrivacy, setIsLeviasTeamAndPrivacy] = useState(false);

  const passwordSetPage = translations.passwordSetPage;
  const anicana = translations.anicana;

  const { updateCodeDMeasurement } = useContext(AuthContext);
  const search = location.search;
  const query = new URLSearchParams(search);
  const code_d_measurement_id = query.get('code_d_measurement_id');

  const updateCodeDMeasurementProcess = async (key) => {
    if (code_d_measurement_id) {
      await updateCodeDMeasurement(code_d_measurement_id, key);
    }
  }

  useEffect(() => {
    updateCodeDMeasurementProcess('is_view_register_page');
  }, [code_d_measurement_id])

  useEffect(() => {
    if (idmsRegistrationStep) {
      Swal.close();
      let codeDKey = '', pathname = '/register/idms/', referrer = '/register/idms', title = 'Register - ', sendGA = false;
      switch (idmsRegistrationStep) {
        case "profileName":
          codeDKey = 'is_input_profile_name';
          pathname += 'account-name';
          title += 'Account Name';
          sendGA = true;
          break;
        case "profileIcon":
          codeDKey = 'is_input_profile_image';
          pathname += 'profile-image';
          referrer += '/account-name'
          title += 'Profile Image';
          sendGA = true;
          break;
        case "setEmail":
          codeDKey = 'is_input_email';
          pathname += 'email';
          referrer += '/profile-image';
          title += 'Email';
          sendGA = true;
          break;
        case "emailVerify":
          codeDKey = 'is_input_email_otp';
          pathname += 'email-verify';
          referrer += '/email';
          title += 'Email Verification';
          sendGA = true;
          break;
        case "setPhone":
          codeDKey = 'is_input_phone';
          pathname += 'phone';
          referrer += '/email-verify';
          title += 'Phone';
          sendGA = true;
          break;
        case "phoneVerify":
          codeDKey = 'is_input_phone_otp';
          pathname += 'phone-verify';
          referrer += '/phone';
          title += 'Phone Verification';
          sendGA = true;
          break;
        case "setPassword":
          pathname += 'password';
          referrer += '/phone-verify';
          title += 'Password';
          sendGA = true;
          break;
        default: break;
      }

      if (sendGA) {
        sendReactGAPageView(pathname, referrer, title);
      }
      
      if (codeDKey) {
        updateCodeDMeasurementProcess(codeDKey);
      }
    }
  }, [idmsRegistrationStep]);

  const onCloseHandler = () => {
    setOnShow(false);
  };

  const onShowModalHandler = () => {
    setOnShow(true);
  };

  const onShowHandler = (type, status) => {
    if (type === "password") {
      setShowPassword(status);
    } else if (type === "confirm-password") {
      setShowConfirmPassword(status);
    }
  };

  const onAcceptHandler = () => {
    // setCheckedTerm(true);
    onCheckHandler("term", true);
    setIdmsRegistrationStep("signUpForm");
  };

  const onAcceptPolicy = () => {
    // setCheckedPolicy(true);
    onCheckHandler("policy", true);
    setIdmsRegistrationStep("signUpForm");
  };

  const textChangeHandler = (type, value = "") => {
    if (type === "email") {
      setEmail(value);
      emailValidator(type, value);
    } else if (type === "confirm-email") {
      setConfirmEmail(value);
      emailValidator(type, value);
    } else if (type === "phone") {
      setPhoneNumber(value);
      phoneValidator("phone", value);
    } else if (type === "confirm-phone") {
      setConfirmPhoneNumber(value);
      phoneValidator("con-phone", value);
    } else if (type === "verify-email-code") {
      if (value?.length > 5) {
        emailVerificationCode = value;
        //setEmailVerificationCode(value);
        setEmailCodeValid(true);
      } else {
        setEmailCodeValid(false);
      }
    } else if (type === "verify-phone-code") {
      if (value?.length > 5) {
        smsVerificationCode = value;
        setPhoneCodeValid(true);
      } else {
        setPhoneCodeValid(false);
      }
      //setSmsVerificationCode(value);
    } else if (type === "account-name") {
      if (
        value?.length > 0 &&
        value?.length < 21 &&
        (/^[a-zA-Z0-9_ ]*$/g.test(value) ||
          /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g.test(
            value
          ))
      ) {
        setAccountNameValid(true);
      } else {
        setAccountNameValid(false);
      }
      setAccountName(value);
    } else if (type === "password") {
      setPassword(value);
      passwordValidator("password", value);
    } else if (type === "confirm-password") {
      setConfirmPassword(value);
      passwordValidator("con-password", value);
    }
  };

  const nextStepHandler = (e) => {
    var element = document.querySelector(".mainbody ");
    element.scrollTop = 0;
    setLoading(true);
    if (idmsRegistrationStep === "profileName") {
      onRegisterProfileNameHandler();
    } else if (idmsRegistrationStep === "setEmail") {
      onRegisterEmailHandler();
    } else if (idmsRegistrationStep === "emailVerify") {
      onVerifyEmailHandler(e);
    } else if (idmsRegistrationStep === "setPhone") {
      onRegisterPhoneHandler();
    } else if (idmsRegistrationStep === "phoneVerify") {
      onVerifyPhoneHandler(e);
    } else if (idmsRegistrationStep === "setPassword") {
      onRegisterAccountNameHandler();
    }
  };

  const onRegisterProfileNameHandler = async () => {
    setLoading(true);
    var response = await fetchData(
      `register/verify-account-name`,
      { account_name: accountName },
      "post",
      true
    );
    if (response.ok) {
      if (response?.data?.response?.errorMessage || response?.data?.response?.errors || response?.data?.message) {
        setLoading(false);
        let message = response.data?.errorMessage || response.data?.message;
        LeviasSwalWrong.fire({
          title: "Error",
          text: message,
        });
      } else {
        setLoading(false);
        setIdmsRegistrationStep("profileIcon");
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
      });
    }
  };

  const onRegisterEmailHandler = async () => {
    setLoading(true);
    var response = await fetchData(
      `register/email`,
      { email, email_confirmation: email },
      "post",
      true
    );
    if (response.ok) {
      localStorage.setItem("email", email);
      if (response.data.errorMessage) {
        setLoading(false);
        let message = response.data.errorMessage;
        LeviasSwalWrong.fire({
          title: "Error",
          text: message,
        });
      } else if (response.data.errors) {
        setLoading(false);
        let message = response.data.message;
        LeviasSwalWrong.fire({
          title: "Error",
          text: message,
        });
      } else if (response.data.transactionId) {
        setLoading(false);
        setIdmsRegistrationStep("emailVerify");
        setTransactionId(response.data.transactionId);
      } else if (response.data.message) {
        setLoading(false);
        let message = response.data.message;
        LeviasSwalWrong.fire({
          title: "Error",
          text: message,
        });
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
      });
    }
  };

  const onVerifyEmailHandler = async (value) => {
    if (emailVerificationCode == "") {
      emailVerificationCode = value;
    }
    var response = await fetchData(
      `register/verify-email`,
      {
        otp: emailVerificationCode,
        transaction_id: transactionId,
      },
      "post",
      true
    );
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
          let message = response.data.errorMessage;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.message) {
          setLoading(false);
          let message = response.data.message;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.tempToken) {
          setLoading(false);
          setIdmsRegistrationStep("setPhone");
          setTemptoken(response.data.tempToken);
        }
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
      }).then(() => {
        if (
          parseInt((response.data.message || "").replace(/[\D]/g, "")) === 1600
        ) {
          setIdmsRegistrationStep("setEmail");
        }
      });
    }
  };

  const onVerifyPhoneHandler = async (value) => {
    if (smsVerificationCode == "") {
      smsVerificationCode = value;
    }
    var response = await fetchData(
      `register/verify-mobile`,
      {
        otp: smsVerificationCode,
        transaction_id: transactionId,
      },
      "post",
      true
    );
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
          let message = response.data.errorMessage;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.errors || response.data.message) {
          setLoading(false);
          let message = response.data.message;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.registrationToken) {
          setLoading(false);
          setIdmsRegistrationStep("setPassword");
          setTemptoken(response.data.registrationToken);
        }
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
      }).then(() => {
        if (
          parseInt((response.data.message || "").replace(/[\D]/g, "")) === 1600
        ) {
          setIdmsRegistrationStep("setPhone");
        }
      });
    }
  };

  const onRegisterPhoneHandler = async () => {
    var response = await fetchData(
      `register/mobile`,
      {
        mobile_number: phoneNumber,
        temp_token: tempToken,
      },
      "post",
      true
    );
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
          let message = response.data.errorMessage;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.errors || response.data.message) {
          setLoading(false);
          let message = response.data.message;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.transactionId) {
          setLoading(false);
          setIdmsRegistrationStep("phoneVerify");
          setTransactionId(response.data.transactionId);
        }
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
      });
    }
  };

  const onRegisterAccountNameHandler = async () => {
    setLoading(true);
    let body = {
      registration_token: tempToken,
      account_name: accountName,
      password,
      password_confirmation: confirmPassword,
      r_server: "1", // portal
    };
    if (imageFile) {
      body = Object.assign(body, { image: imageFile });
    }
    if (referral_code) {
      body = Object.assign(body, { referral_code })
    }
    var response = await fetchData(`register`, body, "post", true);
    if (response.ok) {
      if (response.data) {
        if (response.data.errorMessage) {
          setLoading(false);
          let message = response.data.errorMessage;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data.message) {
          setLoading(false);
          let message = response.data.message;
          LeviasSwalWrong.fire({
            title: "Error",
            text: message,
          });
        } else if (response.data) {
          await updateCodeDMeasurementProcess('is_complete_registration');
          setLoading(false);
          LeviasSwalCorrect.fire({
            title: strings.formatString(
              passwordSetPage.Your_LEVIAS_ID_has_been_successfully_created
            ),
            text: strings.formatString(passwordSetPage.Please_sign_in),
            confirmButtonText: strings.formatString(passwordSetPage.SIGN_IN),
          }).then((res) => {
            navigate(`/login/idms/${callId}/${signText + location.search}`)
          });
        }
      }
    } else {
      setLoading(false);
      LeviasSwalWrong.fire({
        title: "Error",
        text: response.data.message,
        //text:'Something went wrong!'
      });
    }
  };

  const onConfirmAccName = () => {
    setConfirmedAccName(true);
  };

  const emailValidator = (value, result) => {
    if (value === "email") {
      setEmailValidated(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(result)
      );
      if (confirmEmail) {
        if (result === confirmEmail) {
          setConfirmEmailValidated(true);
        }
      }
    } else if (value === "confirm-email") {
      setConfirmEmailValidated(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(result) &&
        email === result
      );
    }
  };

  const passwordValidator = (value, result = "") => {
    if (value === "password") {
      if (result?.length > 7) {
        if (result === confirmPassword) {
          setPasswordValid(true);
        } else {
          setPasswordValid(false);
        }
      } else {
        setPasswordValid(false);
      }
    } else if (value === "con-password") {
      if (result?.length > 7) {
        if (password === result) {
          setPasswordValid(true);
        } else {
          setPasswordValid(false);
        }
      } else {
        setPasswordValid(false);
      }
    }
  };

  const phoneValidator = (value, result = "") => {
    if (value === "phone") {
      if (result?.length > 7) {
        if (result === confirmPhoneNumber) {
          setPhoneValid(true);
        } else {
          setPhoneValid(false);
        }
      } else {
        setPhoneValid(false);
      }
    } else if (value === "con-phone") {
      if (result?.length > 7) {
        if (phoneNumber === result) {
          setPhoneValid(true);
        } else {
          setPhoneValid(false);
        }
      } else {
        setPhoneValid(false);
      }
    }
  };

  const leviasLayout = (val = false) => {
    setIsLeviasLayout(true);
    setIsLeviasTeamAndPrivacy(val);
  };

  const onCheckHandler = (type, status) => {
    if (type === "term") {
      setCheckedTerm(status);
    } else if (type === "policy") {
      setCheckedPolicy(status);
    }
    if (
      (type === "term" && checkedPolicy === true && status !== false) ||
      (type === "policy" && checkedTerm === true && status !== false)
    ) {
      setCheckedValid(true);
    } else {
      setCheckedValid(false);
    }
    return;
  };

  const body = useMemo(() => {
    return (
      <>
        <IDMSRegistrationForm
          language={language}
          currentStep={idmsRegistrationStep}
          completeStep={0}
          nextStepHandler={nextStepHandler}
          textChangeHandler={textChangeHandler}
          phoneNumber={phoneNumber}
          email={email}
          confirmEmail={confirmEmail}
          smsVerificationCode={smsVerificationCode}
          emailVerificationCode={emailVerificationCode}
          accountName={accountName}
          confirmPassword={confirmPassword}
          password={password}
          confirmedAccName={confirmedAccName}
          setPhoneNumber={setPhoneNumber}
          emailValidator={emailValidator}
          confirmEmailValidated={confirmEmailValidated}
          emailValidated={emailValidated}
          loading={loading}
          onCheckHandler={onCheckHandler}
          checkedTerm={checkedTerm}
          checkedPolicy={checkedPolicy}
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onAcceptHandler={onAcceptHandler}
          onAcceptPolicy={onAcceptPolicy}
          showPassword={showPassword}
          showConfirmPassword={showConfirmPassword}
          onShowHandler={onShowHandler}
          accountNameValid={accountNameValid}
          onShowModalHandler={onShowModalHandler}
          emailCodeValid={emailCodeValid}
          phoneCodeValid={phoneCodeValid}
          checkedvalid={checkedvalid}
          setImageFile={setImageFile}
          phoneValid={phoneValid}
          confirmPhoneNumber={confirmPhoneNumber}
          passwordValid={passwordValid}
          leviasLayout={leviasLayout}
          transactionId={transactionId}
          tempToken={tempToken}
        />
      </>
    );
  }, [
    language,
    email,
    confirmEmail,
    smsVerificationCode,
    emailVerificationCode,
    idmsRegistrationStep,
    phoneNumber,
    accountName,
    confirmedAccName,
    password,
    confirmPassword,
    emailValidated,
    confirmEmailValidated,
    loading,
    checkedPolicy,
    checkedTerm,
    showPassword,
    showConfirmPassword,
    accountNameValid,
    emailCodeValid,
    phoneCodeValid,
    checkedvalid,
    imageFile,
    phoneValid,
    confirmPhoneNumber,
    passwordValid,
  ]);

  return (
    <>
      {<BottomModal onCloseHandler={onCloseHandler} onShow={onShow} />}
      <AppWrapper
        isLeviasLayout={isLeviasLayout}
        isLeviasHandler={"register"}
        disableSidebar
        title={anicana.headerTitle}
        isForm
        isCenter
        isLeviasTeamAndPrivacy={isLeviasTeamAndPrivacy}
      >
        <Container fluid className="d-flex flex-row auth-container">
          {body}
        </Container>
        {/* <div style={{ marginBottom: 10 }}>
          <div style={{ marginBottom: '10vh' }}></div>
          <Footer />
        </div> */}
      </AppWrapper>
    </>
  );
}

export default Register;
